.membership-page {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  width         : 100%;
  height        : 100%;


  .membership-guide__box {
    display       : flex;
    width         : 100%;
    flex-direction: column;
    align-items   : center;
    padding-top   : 80px;
    border-top    : solid 1px #e7e7e7;
    padding-bottom: 80px;

    &>header {
      display       : flex;
      flex-direction: column;
      align-items   : center;
      margin-bottom : 24px;

      h3 {
        font-size    : 48px;
        font-weight  : bold;
        font-stretch : normal;
        color        : #1a1829;
        margin-bottom: 8px;
      }

      p {
        font-size   : 20px;
        font-stretch: normal;
        color       : #777777;
      }
    }

    ul {
      display              : grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap             : 32px;
      margin-top           : 24px;
      width                : 100%;


      li {
        width          : 100%;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        box-shadow     : 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
        padding        : 40px 30px;
        box-sizing     : border-box;
        border-radius  : 24px;
        overflow       : hidden;


        h5 {
          font-size     : 24px;
          font-weight   : bold;
          font-stretch  : normal;
          font-style    : normal;
          line-height   : 1.08;
          letter-spacing: normal;
          text-align    : center;
          color         : $primary-color;
        }

        b {
          margin-top    : 12px;
          font-size     : 18px;
          font-weight   : bold;
          font-stretch  : normal;
          font-style    : normal;
          line-height   : 1.33;
          letter-spacing: normal;
          text-align    : center;
          color         : #757182;
        }

        span {
          margin-top    : 4px;
          font-size     : 14px;
          font-weight   : normal;
          font-stretch  : normal;
          font-style    : normal;
          line-height   : 1.33;
          letter-spacing: normal;
          text-align    : center;
          color         : #757182;
        }

        .youtube__box {
          margin-top: 24px;
          width     : 100%;
          height    : 280px;
        }
      }
    }
  }

  .membership-request-line__box {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-top    : 140px;

    &>header {
      display       : flex;
      flex-direction: column;
      align-items   : center;

      h3 {
        font-size    : 48px;
        font-weight  : bold;
        font-stretch : normal;
        color        : #1a1829;
        margin-bottom: 8px;
      }

      p {
        font-stretch: normal;
        font-size   : 20px;
        font-stretch: normal;
        color       : #777777;
      }
    }
  }

  .membership-select__box {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-top    : 48px;

    &>header {
      display      : flex;
      margin-bottom: 24px;

      span {
        font-stretch: normal;
        font-size   : 20px;
        font-stretch: normal;
        color       : #777777;
      }
    }

    &>ul {
      display        : flex;
      justify-content: center;

      li {
        cursor         : pointer;
        width          : 274px;
        height         : 80px;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        border         : 2px solid #d1d1d1;
        border-radius  : 24px;
        margin-right   : 24px;

        &:last-child {
          margin-right: 0px;
        }


        b {
          font-size: 24px;
          color    : #777777;
        }

        span {
          font-size: 14px;
          color    : #777777;

        }

        &.on {
          background: $primary-color;
          border    : 2px solid $primary-color;

          span {
            color: #fff;

          }

          b {
            color: #fff;
          }
        }
      }
    }

    .input__box {
      height        : 84px;
      display       : flex;
      width         : 580px;
      box-sizing    : border-box;
      padding-bottom: 2px;
      border-radius : 24px;
      overflow      : hidden;

      input {
        width                    : 100%;
        height                   : 100%;
        border                   : none;
        outline                  : none;
        text-align               : center;
        font-size                : 24px;
        color                    : $text-color;
        flex                     : 1;
        border                   : 2px solid #d1d1d1;
        border-top-left-radius   : 24px;
        border-bottom-left-radius: 24px;
        box-sizing               : border-box;
        border-right             : none;

        &:disabled {
          opacity   : 0.5;
          background: #e9e9e9;
        }

        &::placeholder {
          font-size: 16px;
        }
      }

      button {
        width : 160px;
        height: 100%;
        width : 160px;
        height: 100%;

      }

      .check-button {
        background-color: $primary-color;
        border          : none;
        outline         : none;
        color           : #fff;
        font-size       : 16px;
        font-weight     : bold;
      }
    }

    .member-info__box {
      display       : flex;
      flex-direction: column;
      margin-top    : 16px;

      ul {
        display: flex;

        li {
          display     : flex;
          align-items : center;
          margin-right: 24px;

          &:last-child {
            margin-right: 0px;
          }

          span {
            font-size: 16px;
            color    : #777;
          }
        }
      }
    }

  }

  .membership-agree__box {
    margin-top    : 48px;
    display       : flex;
    flex-direction: column;
    align-items   : center;

    b {
      color: rgb(245, 94, 94);
    }
  }

  .membership-requset__box {
    // border-top     : solid 1px #e7e7e7;
    display           : flex;
    // padding-top    : 48px;
    margin-top        : 48px;
    justify-content   : center;
    flex-direction    : column;
    align-items       : center;

    button {
      width        : 100%;
      max-width    : 580px;
      height       : 96px;
      background   : $primary-color;
      border-radius: 100px;

      &:disabled {
        opacity: 0.4;
      }

      span {
        color      : #fff;
        font-size  : 32px;
        font-weight: bold;

        em {
          font-size: 26px;
        }
      }
    }

    .point-guide__box {
      display        : flex;
      justify-content: center;

      span {
        color    : #efefef;
        font-size: 16px;
      }
    }
  }

  .paln-help__box {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-top    : 48px;
    margin-bottom : 80px;

    ul {
      display        : flex;
      flex-direction : column;
      justify-content: center;

      li {
        display      : flex;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0px;
        }

        span {
          font-size: 16px;
          color    : $placeholder-color;
        }

        b {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.region__container {
  display       : flex;
  width         : 480px;
  flex-direction: column;

  .header__box {
    display        : flex;
    width          : 100%;
    justify-content: center;
    height         : 40px;
    border-bottom  : solid 1px #777777;

    span {
      font-size: 20px;
    }

    .ico__box {
      position: absolute;
      right   : 20px;
    }
  }

  ul {
    flex          : 1;
    max-height    : 50vh;
    display       : flex;
    flex-direction: column;
    height        : 100%;
    overflow-y    : auto;

    li {
      display    : flex;
      align-items: center;
      height     : 60px;
      min-height : 60px;
      cursor     : pointer;

      &:hover {
        span {
          font-weight: bold;
        }
      }

      span {
        font-size: 20px;
      }
    }
  }

}



@media only screen and (max-width: 1280px) {
  .membership-page {
    .membership-request-line__box {
      margin-top: 48px;

      &>header {
        h3 {
          font-size: 32px;
        }

        p {
          font-size: 16px;
        }
      }

    }

    .membership-select__box {
      margin-top: 24px;

      &.year {
        &>ul {
          display              : grid;
          grid-template-columns: repeat(2, 1fr);
          gap                  : 24px;
          box-sizing           : border-box;

          li {


            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }

      &>ul {
        width: 100%;

        li {
          width : 100%;
          flex  : 1;
          height: 60px;

          b {
            font-size: 18px;
          }
        }
      }


      .input__box {
        width : 100%;
        height: 60px;

        input {
          font-size: 18px;
        }

        .check-button {
          font-size: 14px;
          width    : 120px;
        }
      }
    }

    .membership-requset__box {
      button {
        height: 72px;

        span {
          font-size: 18px;

          em {
            font-size: 16px;
          }
        }
      }

      .point-guide__box {
        span {
          font-size: 12px;
        }
      }
    }

    .paln-help__box {
      margin-top   : 24px;
      margin-bottom: 48px;

      ul {
        li {
          span {
            font-size: 12px;
          }
        }
      }
    }

    .membership-guide__box {
      padding-top   : 48px;
      padding-bottom: 48px;

      &>header {
        h3 {
          font-size: 28px;
        }

        p {
          font-size : 16px;
          text-align: center;
        }
      }

      ul {
        display       : flex;
        flex-direction: column;

        li {
          padding: 16px;
        }
      }
    }
  }

  .region__container {
    width: 260px;

  }
}