.login-page {
  display        : flex;
  flex-direction : column;
  min-height     : 100vh;
  align-items    : center;
  justify-content: center;
  overflow-y     : auto;

  .login__container {
    margin-top    : 24px;
    margin-bottom : 24px;
    width         : 450px;
    display       : flex;
    flex-direction: column;
    align-items   : center;


    .input__box {
      margin-top    : 24px;
      width         : 100%;
      display       : flex;
      flex-direction: column;

      .form-input {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .terms__box {
      display        : flex;
      align-items    : center;
      justify-content: flex-start;
      width          : 100%;
      margin-top     : 24px;

      .circle {
        display      : flex;
        width        : 12px;
        height       : 12px;
        border-radius: 50%;
        border       : solid 1px $primary-color;
        margin-right : 4px;
        cursor       : pointer;

        &.on {
          background: $primary-color;
        }
      }

      span {
        cursor   : pointer;
        font-size: 14px;
        color    : $placeholder-color;
      }

      b {
        font-size  : 14px;
        font-weight: bold;
        cursor     : pointer;
        color      : $placeholder-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .save__box {
      margin-top : 24px;
      display    : flex;
      align-items: center;
      margin-top : 24px;
      width      : 100%;

      .circle {
        display      : flex;
        width        : 12px;
        height       : 12px;
        border-radius: 50%;
        border       : solid 1px $primary-color;
        margin-right : 4px;
        cursor       : pointer;

        &.on {
          background: $primary-color;
        }
      }

      span {
        cursor   : pointer;
        font-size: 14px;
        color    : $placeholder-color;
      }
    }

    .button__box {
      margin-top    : 12px;
      width         : 100%;
      display       : flex;
      flex-direction: column;
      margin-bottom : 16px;
    }

    .option__box {
      display        : flex;
      justify-content: flex-end;
      align-items    : center;
      width          : 100%;
      margin-bottom  : 24px;


      span {
        cursor     : pointer;
        color      : $placeholder-color;
        white-space: nowrap;
        font-size  : 16px;

        &:hover {
          text-decoration: underline;
        }
      }


    }

    .guide-text__box {
      display      : flex;
      margin-bottom: 12px;

      p {
        font-size: 14px;
        color    : #777;
      }
    }

    .guide-text-notice__box {
      display      : flex;
      margin-bottom: 12px;

      p {
        font-size: 14px;
        color    : #e43535;
      }
    }

    .signup-button__box {
      display       : flex;
      flex-direction: column;
      width         : 100%;

      header {
        display        : flex;
        justify-content: center;
        margin-bottom  : 12px;
        align-items    : center;

        span {
          color    : $placeholder-color;
          font-size: 13px;
        }

        .line {
          display   : flex;
          flex      : 1;
          height    : 1px;
          background: $placeholder-color;

          &.left {
            margin-right: 12px;
          }

          &.right {
            margin-left: 12px;
          }
        }

      }
    }
  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {}

@media only screen and (min-width: 766px) and (max-width: 1024px) {}

@media only screen and (max-width: 765px) {
  .login-page {
    .login__container {
      width: 100%;
    }
  }
}